







import Storyblok, { StoryblokComponent } from 'storyblok-js-client';
import { defineComponent } from '@vue/composition-api';
import { CMSService } from '~/services/storyBlok/cmsService';
import DriftFacade from '~/components/DriftFacade.vue';
import storeMutations from '~/store/constants/storeMutations';
import {
  // Announcement,
  BackgroundTheme,
} from '@fc/angie-ui';

interface LayoutStoryResp {
  body: StoryblokComponent<string>[];
}

export default defineComponent({
  name: 'BaseLayout',
  components: {
    DriftFacade,
    // Announcement,
  },
  data() {
    return {
      layoutStory: [] as StoryblokComponent<string>[],
      theme: BackgroundTheme.red,
    };
  },
  async fetch() {
    const storyBlok = new CMSService({ cmsService: this.$storyapi as Storyblok });
    const storyblokData = await storyBlok.get('data/navigation');
    const storyResp = storyblokData?.data?.story?.content || [];

    this.$store.commit(storeMutations.SET_NAV_DATA, storyResp);

    const layoutData = await storyBlok.get('layout');
    const layoutContent: LayoutStoryResp = layoutData?.data?.story?.content || [];
    this.layoutStory = layoutContent.body;
  },
  computed: {
    displayBanner() {
      const isNavTypeConfirmation = this.$store.getters.navType === 'confirmation';
      const isNavTypeGQ = this.$store.getters.navType === 'gq';
      const isCurrentRouteShopProductsFightcamp = this.$store.getters.currentRoute?.name === 'shop-products-fightcamp';

      return (
        !(isNavTypeConfirmation || isNavTypeGQ || isCurrentRouteShopProductsFightcamp)
      );
    },
  },
  methods: {
    handleClick() {
      this.$router.push('/shop/products/fightcamp');
    },
  },
});
