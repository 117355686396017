/* eslint-disable no-nested-ternary */
// eslint-disable-next-line import/no-extraneous-dependencies
import { useAnalytics } from '@fc/app-common';
import { GrowthBook } from '@growthbook/growthbook';
import storeMutations from '~/store/constants/storeMutations';

interface Store {
  commit: (mutation: string, payload: any) => void;
}

// from Growthbook auto-attributes plugin https://github.com/growthbook/growthbook/blob/main/packages/sdk-js/src/plugins/auto-attributes.ts
function getBrowserDevice(ua: string | null | undefined): { browser: string; deviceType: string; uiDeviceType: string } {
  const safeUA = ua || ''; // Prevent errors if `ua` is null/undefined

  // Detect browser
  const browser = safeUA.match(/Edg/) ? 'edge'
    : safeUA.match(/Chrome/) ? 'chrome'
      : safeUA.match(/Firefox/) ? 'firefox'
        : safeUA.match(/Safari/) ? 'safari' : 'unknown';

  // Detect device type based on UA
  const deviceType = safeUA.match(/Mobi/) ? 'mobile' : 'desktop';

  // Handle SSR safely
  const width = typeof window !== 'undefined' ? window.innerWidth : 1200; // Default to desktop
  const uiDeviceType = width < 768 ? 'mobile' : width < 1200 ? 'tablet' : 'desktop';

  return { browser, deviceType, uiDeviceType };
}

export async function createGrowthbookInstance(store: Store) {
  const segmentAnonymousId = window.analytics.user().anonymousId();

  const isDev = process.env.NUXT_ENV_IS_DEV === 'true';

  const growthbookInstance = new GrowthBook({
    apiHost: process.env.NUXT_ENV_GROWTHBOOK_URL,
    clientKey: process.env.NUXT_ENV_GROWTHBOOK_KEY,
    attributes: {
      id: segmentAnonymousId,
      ...getBrowserDevice(navigator.userAgent),
    },
    trackingCallback: (experiment, result) => {
      // trackingCallback is called when evalFeature is called
      const exp = { id: experiment.key, variant: result.key };
      store.commit(storeMutations.REGISTER_GROWTHBOOK_EXPERIMENTS, exp);
    },
    enableDevMode: isDev,
  });

  await growthbookInstance.loadFeatures();
  const features = growthbookInstance.getFeatures();
  const featureKeys = Object.keys(features);

  const analytics = useAnalytics();

  if (analytics) {
    featureKeys.forEach((featureKey) => {
      const isOn = growthbookInstance.isOn(featureKey);
      store.commit(storeMutations.SET_FEATURE_FLAG, { key: featureKey, isOn });

      // evalFeature will trigger the trackingCallback and update the store
      const result = growthbookInstance.evalFeature(featureKey);

      window.dataLayer.push({
        event: 'experiment_viewed',
        experiment: {
          name: featureKey,
          anonId: segmentAnonymousId,
          result,
        },
      });
    });

    store.commit(storeMutations.SET_GROWTHBOOK_INITIALIZED, true);
  }

  return growthbookInstance;
}
