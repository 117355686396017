import { ActionContext, Commit, GetterTree } from 'vuex';
import axios from 'axios';
import { useAnalytics } from '@fc/app-common';
import { FormattedProduct, FormattedVariant } from '@fc/app-common/src/services/Shopify/types';
import { transformShopifyProductForAnalytics } from '~/selectors/selectors';
import { FCProductType, IVariant } from '~/types/fightcamp';
import productsData from '~/data/index';

// TODO review types here
const analytics = useAnalytics();

interface ShopifyState extends CheckoutState {}

interface CartItem {
  id: string;
  qty: number;
  type?: FCProductType;
  membershipGID?: string | null;
}

interface Options extends CartItem {
  delay: boolean;
}

// TODO create a proper RootState type
export const actions = {
  async insertItemToCartV2({
    commit, dispatch, getters,
  }: ActionContext<ShopifyState, ShopifyState>, opts: Options) {
    console.info('insertItemToCartV2', opts);
    try {
      const { delay, ...item } = opts || {};
      if (delay) { await simulateDelayForCartLoading(commit); }

      if (getters.isShopifyPackage(item.id) && getters.hasTransferredMembership) {
        console.log('removing membership from cart to add package');
        await dispatch('removeItemFromCartV2', { id: getters.hasTransferredMembership?.variants?.find(variant => variant.sku === 'FC-Secondhand-Membership')?.id });
      }

      if (item.type === 'subs' && getters?.cartProducts?.packageInCart) {
        console.log('removing package from cart to add membership');
        await dispatch('removeItemFromCartV2', { id: getters.cartProducts?.packageInCart?.variantId });
      }

      if (item.type === 'subs' && getters.hasTransferredMembership) {
        console.log('cannot add 2 memberships to cart');
        return; // cannot add 2 memberships to cart
      }

      const shouldSwapPackages = getters?.cartProducts?.packageInCart && getters.isShopifyPackage(item.id);
      if (shouldSwapPackages) {
        console.log('swapping packages');
        await dispatch('removeItemFromCartV2', { id: getters.cartProducts?.packageInCart?.variantId }); // this will call the track remove product, was this messing with analytics? should we fix moving forward?
      }

      const fullProduct = getters.getProductVariantById(item.id);

      // verify item ID exists (this handles the use case of removing the Shopify flag and customers having old cart items in their session)
      if (!fullProduct) {
        if (process.client) {
          this.$bugsnag.notify(new Error(`Product not found: ${item.id}`));
        }
        return;
      }

      // pass the membershipGID to the mutation to insert a membership if the product comes with one
      commit('insertItemToCartV2', { ...item, membershipGID: fullProduct?.metadata?.membershipGID ?? null });

      dispatch('trackProductAddedV2', item.id);
      await dispatch('updateCart');
    } catch (e) {
      if (process.client) {
        this.$bugsnag.notify(e);
      }
    }
  },
  async removeItemFromCartV2({ commit, dispatch, getters }:ActionContext<ShopifyState, ShopifyState>, opts: Options) {
    console.info('removeItemFromCartV2', opts);
    try {
      const { delay, ...item } = opts || {};

      if (delay) { await simulateDelayForCartLoading(commit); }

      commit('removeItemFromCartV2', item);
      dispatch('trackProductRemovedV2', item.id);

      // remove items that "belong" to a product (like warranties or deal items)
      const productToRemove = getters.getProductVariantById(item.id);
      if (productToRemove.productType === 'package') {
        const typesToRemove = ['warranty', 'subscription', 'package', 'packageUpsell'];
        const productsToRemove = getters.cartProducts.shopifyItems.filter(p => typesToRemove.includes(p.productType)); // TODO how can we do this without hardcoding type?
        productsToRemove.forEach(p => {
          // need to remove all quanties of product
          for (let i = 0; i < p.variant.qty; i++) {
            commit('removeItemFromCartV2', { id: p.variant.id });
          }
        });
      }

      await dispatch('updateCart');
    } catch (e) {
      if (process.client) {
        this.$bugsnag.notify(e);
      }
    }
  },
  async syncCartV2({
    commit, state, dispatch, getters,
  }:ActionContext<ShopifyState, ShopifyState>, queryItems?: CartItem[]) {
    if (state.initStarted) { return; }
    commit('setInitStarted');
    console.info('action - syncCartV2');
    try {
      const {
        data: {
          items, email, shippingInfo,
        },
      } = await axios.get('/api/cart', { withCredentials: true });

      const hasQueryItems = queryItems && queryItems.length > 0;
      const hasOldItems = items && items.length > 0 && !(items[0].id).includes('gid');

      // reset the cart if the items are not valid for Shopify toggle or are missing uid. This is in case we switch from non-Shopify cart to shopify cart.
      if (!hasQueryItems && (hasOldItems || getters.hasInvalidProductVariantIdorUID(items))) {
        commit('setCartV2', []);
        await dispatch('updateCart');
        return;
      }

      if (email) { commit('IDENTIFY_BY_EMAIL', email); }
      if (shippingInfo) { commit('SET_SHIPPING_INFO', shippingInfo); }

      // if there are items in the query, use them to populate the cart
      if (queryItems && queryItems.length > 0) {
        queryItems.forEach((product) => {
          dispatch('insertItemToCartV2', product);
        });
      } else {
        // otherwise, use the items from the server
        commit('setCartV2', items);
      }

      await dispatch('updateCart');
      dispatch('trackCartSync');
    } catch (e) {
      if (process.client) {
        this.$bugsnag.notify(e);
      }
      commit('setCartV2', []);
    }
  },
  async swapBagAndRingForBundle({ dispatch }:ActionContext<ShopifyState, ShopifyState>) {
    const prodIds = {
      bundleId: 'gid://shopify/ProductVariant/45729022836972',
      bagId: 'gid: //shopify/ProductVariant/45113921437932',
      ringId: 'gid://shopify/ProductVariant/42890543726828',
    };

    const testIds = {
      bundleId: 'gid://shopify/ProductVariant/41880700911705',
      bagId: 'gid://shopify/ProductVariant/40584970469465',
      ringId: 'gid://shopify/ProductVariant/40045135855705',
    };

    const ids = process.env.IS_PROD === 'true' ? prodIds : testIds;

    await dispatch('removeItemFromCartV2', { id: ids.bagId });
    await dispatch('removeItemFromCartV2', { id: ids.ringId });
    await dispatch('insertItemToCartV2', { id: ids.bundleId });
  },
  trackProductAddedV2({ getters }:ActionContext<ShopifyState, ShopifyState>, variantId: string) {
    const product = getters.getProductVariantById(variantId);
    const transformedProduct = transformShopifyProductForAnalytics({ product, variantId });
    analytics.productAdded(transformedProduct);
    this.$ecommAnalytics.add_to_cart([{
      item_id: product.id,
      item_name: product.title,
      price: product.variants[0].price,
      item_category: product.productType,
      quantity: 1,
    }]);
  },
  trackProductRemovedV2({ getters }:ActionContext<ShopifyState, ShopifyState>, variantId: string) {
    const product = getters.getProductVariantById(variantId);
    const transformedProduct = transformShopifyProductForAnalytics({ product, variantId });
    analytics.productRemoved(transformedProduct);
    this.$ecommAnalytics.remove_from_cart([{
      item_id: product.id,
      item_name: product.title,
      price: product.variants[0].price,
      item_category: product.productType,
      quantity: 1,
    }]);
  },
};

export const mutations = {
  setCartV2(state: ShopifyState, items: CartItem[]) {
    if (!items) return;

    state.cart = items;
    state.cartSyncCompleted = true;
  },
  insertItemToCartV2(state: ShopifyState, item: CartItem) {
    const itemInCart = state.cart.find(i => i.id === item.id);
    if (itemInCart) {
      itemInCart.qty += 1;
    } else {
      state.cart.push({
        ...item,
        qty: 1,
      });
    }
    if (item.membershipGID) {
      state.cart.push({
        id: item.membershipGID,
        qty: 1,
        type: 'subs_only',
      });
    }
  },
  removeItemFromCartV2(state: ShopifyState, itemToRemove: CartItem) {
    const itemFoundInCart = state.cart.find(i => i.id === itemToRemove.id);
    if (!itemFoundInCart) return;

    itemFoundInCart.qty -= 1;
    if (itemFoundInCart.qty <= 0) {
      state.cart = state.cart.filter(i => i.id !== itemToRemove.id);
    }
  },
};

type subscriptionProduct = {
  id: string; // "gid://shopify/Product/1448210497625"
}

export const shopifyGetters: GetterTree<ShopifyState, ShopifyState> = {
  cartProducts: (_, getters) => {
    if (!getters.isShopifyOn || getters.isGQOrder) return null;
    const { cart, membershipInCart } = getters;

    let packageInCart = null;

    const shopifyItems = cart.map((cartItem: CartItem) => {
      const fullProductWithSelectedVariant = getters.fullProductWithSelectedVariant(cartItem.id, cartItem.qty);

      if (fullProductWithSelectedVariant?.productType === 'package') {
        packageInCart = {
          product: { ...fullProductWithSelectedVariant },
          membership: membershipInCart,
          variantId: cartItem.id,
        };
      }

      return fullProductWithSelectedVariant;
    }).filter(item => item.id);

    return {
      shopifyItems,
      packageInCart,
    };
  },
  hasTransferredMembership(_, getters) {
    if (!getters.isShopifyOn || getters.isGQOrder) return null;
    return getters.cartProducts?.shopifyItems?.find((product: FormattedProduct) => product?.variants?.find(variant => variant.sku === 'FC-Secondhand-Membership')); // TODO can we do this without hardcoding the sku?
  },
  getProductVariantById: (_, getters) => (id: string) => getters.productsCollection?.find((p: FormattedProduct) => p.variants?.some(v => v?.id === id)),
  membershipInCart: (_, getters) => {
    if (!getters.isShopifyOn || getters.isGQOrder) return null;
    for (const cartItem of getters.cart) {
      const shopifyProduct = getters.getProductVariantById(cartItem.id);
      const subscriptionGraphQlIds = getters.subscriptionProducts.map((p: subscriptionProduct) => p.id);

      if (shopifyProduct && subscriptionGraphQlIds.includes(shopifyProduct?.id)) {
        return shopifyProduct;
      }
    }

    return null;
  },
  isShopifyPackage: (_, getters) => (id: string): boolean => {
    const product = getters.getProductVariantById(id);
    return product?.productType === 'package';
  },
  hasInvalidProductVariantIdorUID: (_, getters) => (items: FormattedProduct[]) => items?.map(item => getters.getProductVariantById(item?.id)?.variants?.find((variant: FormattedVariant) => variant?.id === item?.id)).some(variant => !variant?.uid),
  fullProductWithSelectedVariant: (_, getters) => (variantId: string, qty: number) => {
    const fullProduct = getters.getProductVariantById(variantId);
    const selectedVariant = fullProduct?.variants?.find(variant => variant.id === variantId);

    const variant: IVariant = {
      id: selectedVariant?.id,
      price: selectedVariant?.price,
      productName: selectedVariant?.name === 'Default Title' ? fullProduct?.title : selectedVariant?.name,
      qty,
      type: fullProduct?.productType || 'equip',
      items: [],
      pricingDescription: fullProduct?.description,
      pricingDetails: fullProduct?.description,
      product: {
        category: fullProduct?.productType,
        id: fullProduct?.id,
        img_url: fullProduct?.image,
        price: selectedVariant?.price,
        title: fullProduct?.title,
      },
      shippingRestricted: false,
      subscriptionNeeded: false,
      sku: selectedVariant?.sku,
      uid: selectedVariant?.uid,
    };

    let includes = null;

    const productJson = Object.values(productsData).find(product => {
      const id = process.env.NUXT_ENV_IS_DEV === 'true' ? product.testShopifyId : product.prodShopifyId;
      return id === fullProduct?.id;
    });

    // add the list of included products to the product
    if (productJson && productJson?.includes?.length > 0) {
      includes = productJson.includes;
    }

    return { ...fullProduct, includes, variant };
  },
};

async function simulateDelayForCartLoading(commit: Commit) {
  commit('SET_PROCESSING_ADD_TO_CART', true);
  await new Promise<void>((resolve) => {
    setTimeout(() => {
      commit('SET_PROCESSING_ADD_TO_CART', false);
      resolve();
    }, 500);
  });
}
