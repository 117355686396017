// eslint-disable-next-line import/no-extraneous-dependencies
import { Context, Plugin } from '@nuxt/types';
// eslint-disable-next-line import/no-extraneous-dependencies
import VueRouter from 'vue-router';
import Vue from 'vue';

import { FCAnalytics, useAnalytics } from '@fc/app-common';
import gtmAnalytics from '~/services/analytics/analytics';

Vue.use(FCAnalytics, {
  writeKey: process.env.NUXT_ENV_SEGMENT_ECOMM,
});

const registerPerRouteAnalytics = (context: Context) => {
  const { app } = context;
  // const analyticsObj = useAnalytics();

  (<VueRouter>app.router).afterEach((to) => {
    console.log('to', to);
    // const name = <string>to.name;

    // if (store.getters.appInitialized) {
    //   analyticsObj.pageViewed({ name, optimizeProperties: store.state.app.experiments });
    // } else {
    //   store.watch((_state, getters) => getters.appInitialized, (appInitialized) => {
    //     if (appInitialized) {
    //       analyticsObj.pageViewed({ name, optimizeProperties: store.state.app.experiments });
    //     }
    //   });
    // }
  });
};

const ecommAnalytics: Plugin = (context, inject) => {
  registerPerRouteAnalytics(context);
  inject('ecommAnalytics', gtmAnalytics);
  inject('segmentAnalytics', useAnalytics());
};

export default ecommAnalytics;
