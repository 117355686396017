import { ActionContext } from 'vuex';
import { IOrdersService } from '~/services/OrdersService';
import { IBugsnagWrapper } from '~/services/BugSnagService';
import { CheckoutState, IShippingDetails } from '~/store/types';
import { createOrUpdateShopifyCart } from './helpers';

interface ICreateCartParams {
  shippingDetails: IShippingDetails;
  cartCoupon?: string;
  eCommService: any;
  ordersApi: IOrdersService;
  bugsnag: IBugsnagWrapper;
}

export async function createCart({
  rootGetters, rootState, commit, dispatch,
}: ActionContext<CheckoutState, any>, payload: ICreateCartParams): Promise<void> {
  console.info('action - createCart', `cartCoupon: ${payload.cartCoupon}`);
  const {
    shippingDetails, cartCoupon, eCommService, ordersApi, bugsnag,
  } = payload;
  const { cartId } = rootState.checkout;

  try {
    commit('setProcessingTotalCost', true, { root: true });
    await createOrUpdateShopifyCart({
      shippingDetails,
      eCommService,
      commit,
      cart: rootGetters.cart,
      couponName: cartCoupon,
      shopifyItemsInCart: rootGetters.cartProducts.shopifyItems,
      rootGetters,
      ordersApi,
      dispatch,
      bugsnag,
    }, cartId);
  } catch (err: any) {
    // this is an error not handled in createOrUpdateShopifyCart, so we need to report it
    bugsnag.notify(err);
    commit('SET_SHIPPING_ERROR', 'We\'re sorry, an error occured. Please try again.', { root: true });
  } finally {
    commit('setProcessingTotalCost', false, { root: true });
  }
}
