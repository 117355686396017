// eslint-disable-next-line import/no-extraneous-dependencies
import { Context, Plugin } from '@nuxt/types';
import { createGrowthbookInstance } from '~/services/GrowthBookService';
import { speeroCode } from '~/utils/speero';

const growthbookPlugin: Plugin = (context: Context): void => {
  window.analytics.ready(async () => {
    const instance = await createGrowthbookInstance(context.store);
    await speeroCode(instance);
  });
};

export default growthbookPlugin;
