/* eslint-disable max-classes-per-file */
import { Commit } from 'vuex';
import { ShopifyProductVariantGID } from '@fc/app-common/src/services/Shopify/types';

export class DataError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'DataError';
  }
}

interface IProductAvailabilityError {
  commit: Commit;
  products: ShopifyProductVariantGID[];
  shopifyItemsInCart: {
    title: string;
    variant: {
      id: string;
    }
  }[]
}

interface IUnshippableError {
  commit: Commit;
  productIds: string[];
  shopifyItemsInCart: {
    title: string;
    variant: {
      id: string;
    }
  }[]
}

export function productAvailabilityError({ commit, products, shopifyItemsInCart }: IProductAvailabilityError): void {
  const productNames = products.map((product) => {
    const p = shopifyItemsInCart.find((item) => item.variant.id === product);
    return p?.title ?? '';
  }).filter((name) => name !== '');

  const message = `The following products are not available in your area: ${productNames.join(', ')}. Please remove them from your cart to continue.`;
  commit('SET_SHIPPING_ERROR', message, { root: true });
  commit('SET_SHIPPING_INVALID', ['country'], { root: true });
}

export function unshippableError({ commit, productIds, shopifyItemsInCart }: IUnshippableError): void {
  const productNames = productIds.map((id) => {
    const p = shopifyItemsInCart.find((item) => item.variant.id === id);
    return p?.title ?? '';
  }).filter((name) => name !== '');

  const message = `The following products are not available in your area: ${productNames.join(', ')}. Please remove them from your cart to continue.`;
  commit('SET_SHIPPING_ERROR', message, { root: true });
  commit('SET_SHIPPING_INVALID', ['country'], { root: true });
}

interface ShopifyError {
  field: string[];
  message: string;
}

export function handleShopifyAdminErrors(errors: ShopifyError[], commit: Commit): string[] {
  const formFieldsMap = new Map([
    ['city', 'city'],
    ['zip', 'postalCode'],
    ['provinceCode', 'state'],
    ['country', 'country'],
    ['email', 'email'],
    ['phone', 'phoneNumber'],
  ]);

  // check if any of the errors are related to the form fields
  const { matchingErrors, nonMatchingErrors } = errors.reduce((acc: { matchingErrors: ShopifyError[], nonMatchingErrors: ShopifyError[] }, error) => {
    const hasMatchingField = error.field.some(field => formFieldsMap.has(field));

    if (hasMatchingField) {
      acc.matchingErrors.push(error);
    } else {
      acc.nonMatchingErrors.push(error);
    }

    return acc;
  }, { matchingErrors: [], nonMatchingErrors: [] });

  const getTheFields = mapFieldsToValues(matchingErrors, formFieldsMap);
  commit('SET_SHIPPING_INVALID', getTheFields, { root: true }); // this will highlight the fields in the form with red border

  const errorMessages = [
    ...(matchingErrors.length > 0 ? ['Please check the highlighted fields'] : []),
    ...nonMatchingErrors.map((error) => error.message),
  ];

  return errorMessages;
}

function mapFieldsToValues(fields: Array<{ field: string[]; message: string }>, formFieldsMap: Map<string, string>): string[] {
  return fields.map(({ field }) => {
    // Find the first element in 'field' that exists as a key in the map
    const matchingKey = field.find(key => formFieldsMap.has(key));
    return matchingKey ? formFieldsMap.get(matchingKey) : null; // Map it to its value or null
  }).filter(value => value !== null); // Remove null values if any
}
