export default {
  title: 'Partner Workout Pack',
  pathId: '',
  testShopifyId: 'gid://shopify/Product/7819193745497',
  prodShopifyId: 'gid://shopify/Product/8701921525996',
  description: '',
  shortDescription: 'Additional Pair of Boxing Gloves, Quick Wraps, Ankle Wraps and Heart Rate Monitor',
  isSoldOut: false,
  primaryImg: {
    src: 'https://a.storyblok.com/f/152150/585x600/d2830b229a/fc-marketplace-accessory-kids-gloves-001.png',
    alt: 'Partner Workout Pack',
  },
  includes: [
    {
      productName: 'Gloves',
    },
    {
      productName: 'Quick Wraps',
    },
    {
      productName: 'Ankle Wraps',
    },
    {
      productName: 'Heart Rate Monitor',
    },
  ],
};
