export const sectionIdMixin = {
  props: {
    trackingId: {
      type: String,
    },
  },
  computed: {
    sectionId() {
      if (this.trackingId) {
        return this.trackingId;
      }

      if (this.codeId) {
        return this.codeId;
      }

      // legacy ID prop, keeping for analytics purposes
      if (this.id) {
        return `${this.id}${this.$options.name}`;
      }

      const routeName = (this.$route.name).charAt(0).toUpperCase() + this.$route.name.slice(1);

      return `${routeName}_${this.$options.name}`;
    },
  },
};
