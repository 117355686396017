import { createOrUpdateOrder } from './actions/orders/createOrUpdateOrder';
import { createOrUpdateShopifyCart } from './actions/cart/helpers';
import { confirmOrder } from './actions/orders/confirmOrder';
import { createCart } from './actions/cart/createCart';

// Needs this to be true so it does not conflict with the existing shared actions
export const namespaced = true;

export const actions = {
  createCart,
  async updateCheckout({
    rootGetters, rootState, commit, dispatch,
  }, payload = {}) {
    console.info('action - updateCheckout');
    console.info('payload', payload);
    const { cartId } = rootState.checkout;
    const { shippingDetails = null, cartCoupon = null } = payload;

    const shipping = shippingDetails || rootGetters.shopifyShipping;

    try {
      commit('setProcessingTotalCost', true, { root: true });

      await createOrUpdateShopifyCart({
        shippingDetails: shipping,
        eCommService: this.$eCommService,
        commit,
        cart: rootGetters.cart,
        couponName: cartCoupon,
        shopifyItemsInCart: rootGetters.cartProducts.shopifyItems,
        dispatch,
        rootGetters,
        ordersApi: this.$ordersApi,
        bugsnag: this.$bugsnag,
      }, cartId);
    } catch (err) {
      // this is an error not handled in createOrUpdateShopifyCart, so we need to report it
      this.$bugsnag.notify(err);
      commit('SET_SHIPPING_ERROR', 'We\'re sorry, an error occured. Please try again', { root: true });
    } finally {
      commit('setProcessingTotalCost', false, { root: true });
    }
  },

  createOrUpdateOrder,
  confirmOrder,
  async handleStripePayment({ rootGetters, dispatch }, { ordersApi, method }) {
    const { cart, membershipInCart } = rootGetters;

    const isOnlySecondHandMembershipInCart = cart.length === 1 && (membershipInCart?.variants[0]?.sku === 'FC-Secondhand-Membership');
    const isAffirmWithPackage = method === 'affirm' && !!membershipInCart;
    const hasMembershipInCartOnly = cart.length === 1 && !!membershipInCart && membershipInCart.variants[0].price === 0;
    const useSetupIntent = hasMembershipInCartOnly || isOnlySecondHandMembershipInCart || isAffirmWithPackage;

    try {
      if (useSetupIntent) {
        await dispatch('orders/createSetupIntent', { ordersApi, method }, { root: true });
      } else {
        await dispatch('orders/createPaymentIntent', { ordersApi, method }, { root: true });
      }
    } catch (err) {
      this.$bugsnag.notify(err);
      throw new Error(err);
    }
  },
  async createPaymentIntent({ commit, rootGetters, rootState }, { ordersApi, method }) {
    const res = await ordersApi.createPaymentIntent({
      costBreakdown: rootGetters.order.costBreakdown,
      shopifyDraftOrderName: rootState.checkout.draftOrderName,
      shopifyDraftOrderId: rootState.checkout.draftOrderId,
      paymentMethod: method,
    });

    const paymentIntentId = res.data.paymentIntentId;
    commit('setPaymentIntentId', paymentIntentId, { root: true });

    return paymentIntentId;
  },
  async createSetupIntent({ commit, rootState }, { ordersApi, method }) {
    const {
      customerId, paymentInformation: { paymentMethodObj },
    } = rootState.checkout;

    const setupIntent = await ordersApi.createSetupIntent({
      customerId,
      paymentMethodId: paymentMethodObj.id,
      shopifyDraftOrderId: rootState.checkout.draftOrderId,
      paymentMethod: method,
    });

    const setupIntentId = setupIntent.data.id;
    commit('setSetupIntentId', setupIntentId, { root: true });

    return setupIntentId;
  },
  async getCustomer({ commit }, payload) {
    console.info('action - getCustomer');
    let customer = {};

    try {
      const { email, id } = payload;
      const response = await this.$ordersApi.getCustomer({ email, id });
      [customer] = response.data;

      if (customer) {
        commit('updateCustomerId', customer.id, { root: true });
        commit('setNewCustomer', customer.newSubCustomer, { root: true });
      }

      return customer;
    } catch (err) {
      // TODO handle errors
      console.error(err);
      this.$bugsnag.notify(err);

      throw new Error(err);
    }
  },
  async createCustomer({ commit }, payload) {
    console.info('action - createCustomer');
    let createdCustomer = {};

    try {
      const { shippingDetails } = payload;

      const requestBody = {
        email: shippingDetails.email,
        shippingDetails: {
          name: `${shippingDetails.firstName} ${shippingDetails.lastName}`,
          phone: shippingDetails.phoneNumber,
          address: {
            line1: shippingDetails.address,
            line2: shippingDetails.address2,
            city: shippingDetails.city,
            state: shippingDetails.state,
            country: shippingDetails.country,
            postal_code: shippingDetails.postalCode,
          },
        },
      };

      createdCustomer = await this.$ordersApi.createCustomer(requestBody);

      commit('updateCustomerId', createdCustomer.data.id, { root: true });
      commit('setNewCustomer', createdCustomer.data.newSubCustomer, { root: true });

      return createdCustomer.data;
    } catch (err) {
      // TODO handle errors
      console.error(err);
      this.$bugsnag.notify(err);

      throw new Error(err);
    }
  },

  async updateCustomer({ rootState }, payload = {}) {
    console.info('action - updateCustomer');
    let updatedCustomer = null;
    const { shippingDetails = null, id: stripeId } = payload;

    try {
      const id = stripeId || rootState.checkout.customerId;

      const requestBody = {
        paymentMethodId: rootState.checkout.paymentInformation?.paymentMethodObj?.id ?? null,
        ...(shippingDetails && {
          shippingDetails: {
            name: `${shippingDetails.firstName} ${shippingDetails.lastName}`,
            phone: shippingDetails.phoneNumber,
            address: {
              line1: shippingDetails.address,
              line2: shippingDetails.address2,
              city: shippingDetails.city,
              state: shippingDetails.state,
              country: shippingDetails.country,
              postal_code: shippingDetails.postalCode,
            },
          },
        }),
      };

      updatedCustomer = await this.$ordersApi.updateCustomer({ id, requestBody });

      return updatedCustomer.data;
    } catch (err) {
      this.$bugsnag.notify(err);

      if (err?.response?.data.type === 'StripeError') {
        throw new Error(err.response.data.message);
      } else {
        throw new Error(err);
      }
    }
  },

  async applyCoupon({
    rootState, rootGetters, commit, dispatch,
  }, coupon) {
    console.info('action - applyCoupon');
    const { cartId, checkoutEmail, shipping } = rootState.checkout;

    try {
      commit('setProcessingTotalCost', true, { root: true });

      const shippingDetails = { ...shipping, email: checkoutEmail };

      const cartObj = await createOrUpdateShopifyCart({
        shippingDetails,
        eCommService: this.$eCommService,
        rootGetters,
        commit,
        cart: rootGetters.cart,
        couponName: coupon,
        dispatch,
        ordersApi: this.$ordersApi,
        bugsnag: this.$bugsnag,
      }, cartId);

      if (cartObj) {
        commit('updateCouponError', '', { root: true });
        commit('updateCoupon', coupon, { root: true });
        commit('setProcessingTotalCost', false, { root: true });
      }
    } catch (err) {
      // this is an error not handled in createOrUpdateShopifyCart, so we need to report it
      this.$bugsnag.notify(err);
      commit('SET_SHIPPING_ERROR', 'We\'re sorry, an error occured. Please try again', { root: true });
      commit('setProcessingTotalCost', false, { root: true });
    }
  },
  async removeCoupon({
    rootState, commit, rootGetters, dispatch,
  }) {
    console.info('action - removeCoupon');
    const { cartId, checkoutEmail, shipping } = rootState.checkout;

    try {
      commit('setProcessingTotalCost', true, { root: true });
      const shippingDetails = { ...shipping, email: checkoutEmail };

      const cartObj = await createOrUpdateShopifyCart({
        shippingDetails,
        eCommService: this.$eCommService,
        rootGetters,
        commit,
        cart: rootGetters.cart,
        couponName: '',
        removeCoupon: true,
        dispatch,
        ordersApi: this.$ordersApi,
        bugsnag: this.$bugsnag,
      }, cartId);

      if (cartObj) {
        commit('updateCouponError', '', { root: true });
        commit('updateCoupon', '', { root: true });
        commit('setProcessingTotalCost', false, { root: true });
      }
    } catch (err) {
      // this is an error not handled in createOrUpdateShopifyCart, so we need to report it
      this.$bugsnag.notify(err);
      commit('SET_SHIPPING_ERROR', 'We\'re sorry, an error occured. Please try again', { root: true });
      commit('setProcessingTotalCost', false, { root: true });
    }
  },
  async calculateEstimatedTax({ commit }, payload) {
    try {
      const res = await this.$ordersApi.createTax(payload);
      const { amountToCollect, rate } = res.data;
      commit('setEstimatedTax', { amountToCollect, rate }, { root: true });
    } catch (err) {
      commit('setEstimatedTaxError', err.response.data.message, { root: true });
    }
  },
};
