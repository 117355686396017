export default {
  title: '3rd/4th Person Accessories',
  pathId: '',
  testShopifyId: 'gid://shopify/Product/7819200102489',
  prodShopifyId: 'gid://shopify/Product/8701923721452',
  description: '',
  shortDescription: 'Additional Pair of Boxing Gloves, Quick Wraps, and Ankle Wraps',
  isSoldOut: false,
  primaryImg: {
    src: 'https://a.storyblok.com/f/152150/585x600/d2830b229a/fc-marketplace-accessory-kids-gloves-001.png',
    alt: 'Partner Workout Pack',
  },
  includes: [
    {
      productName: 'Gloves',
    },
    {
      productName: 'Quick Wraps',
    },
    {
      productName: 'Ankle Wraps',
    },
  ],
};
