import { Dispatch, Commit } from 'vuex';
import { IOrdersService } from '~/services/OrdersService';
import { IBugsnagWrapper } from '~/services/BugSnagService';
import { ICartItem, IShippingDetails } from '~/store/types';
import { productAvailabilityError, unshippableError } from '~/utils/errors';

function cartItemsEqual(a: ICartItem[], b: ICartItem[]): boolean {
  if (a.length !== b.length) return false;

  return a.every(itemA => {
    const itemB = b.find(item => item.id === itemA.id);
    return itemB && itemB.qty === itemA.qty;
  });
}

function mapCartLines(cart: ICartItem[]): any[] {
  return cart.map(item => ({
    merchandiseId: item.id,
    quantity: item.qty,
  }));
}

let previousCart: ICartItem[] = [];

interface CreateOrUpdateShopifyCartOptions {
  shippingDetails?: IShippingDetails | null;
  eCommService: any;
  commit: Commit;
  cart?: ICartItem[];
  couponName?: string;
  removeCoupon?: boolean;
  shopifyItemsInCart?: any[];
  rootGetters: any;
  dispatch: Dispatch;
  ordersApi: IOrdersService;
  bugsnag: IBugsnagWrapper;
}

/**
 * Creates a Shopify Cart and updates Shopify Draft Order, with the provided shipping details. Then saves the order object in the Vuex store..
 *
 * @param {CreateOrUpdateShopifyCartOptions} options - The options for creating the Shopify checkout.
 * @param {string} cartId - The Shopify Cart ID if it already exists.  eg gid://shopify/Cart/Z2NwLXVzLXdlc3QxOjAxSlBCMFBHVEM4VEdLOTUwN0JXNUFOTVYx?key=c737ebab6cf6036a4d8fa8cf0764b8bd
 * @returns {Promise<Object>} - The order object for the created cart and order.
 */
export async function createOrUpdateShopifyCart(options: CreateOrUpdateShopifyCartOptions, cartId: string | null = null): Promise<object | null> {
  const {
    shippingDetails = null,
    eCommService,
    commit,
    cart = [],
    couponName,
    removeCoupon = false,
    shopifyItemsInCart = [],
    rootGetters,
    dispatch,
    ordersApi,
    bugsnag,
  } = options;

  let id = cartId;

  try {
    let readyCart;
    if (id) {
      const cartChanged = !cartItemsEqual(previousCart, cart);

      if (cartChanged) {
        readyCart = await eCommService.cartLinesReplace({
          cartId: id,
          lines: mapCartLines(cart),
        });
        // Update previous cart after successful update
        previousCart = [...cart];
      }

      if (shippingDetails) {
        readyCart = await eCommService.updateAddress({
          cartId: id,
          addresses: [
            {
              id: rootGetters.getDeliveryAddressId,
              address: {
                deliveryAddress: {
                  address1: shippingDetails.address,
                  address2: shippingDetails.address2,
                  city: shippingDetails.city,
                  provinceCode: shippingDetails.state,
                  countryCode: shippingDetails.country,
                  zip: shippingDetails.postalCode,
                  firstName: shippingDetails.firstName,
                  lastName: shippingDetails.lastName,
                  phone: shippingDetails.phoneNumber,
                },
              },
              selected: true,
            },
          ],
          email: shippingDetails.email,
        });

        commit('setDeliveryAddressId', readyCart.deliveryAddressId, { root: true });
      }

      if (couponName) {
        const currentSubtotalInCents = rootGetters.order.costBreakdown.productCost;
        readyCart = await eCommService.updateCartCoupon({ cartId: id, discountCodes: [couponName], currentSubtotalInCents });
      }

      if (removeCoupon) {
        readyCart = await eCommService.updateCartCoupon({ cartId: id, discountCodes: [] });
      }
    } else {
      const cartRequest = {
        buyerIdentity: {
          countryCode: shippingDetails?.country ?? '',
          email: shippingDetails?.email ?? '',
          phone: shippingDetails?.phoneNumber ?? '',
        },
        lines: mapCartLines(cart),
      };

      const createdCart = await eCommService.createCart(cartRequest);
      // Store initial cart state
      previousCart = [...cart];
      id = createdCart.id;
      commit('setCartId', id, { root: true });

      if (shippingDetails) {
        readyCart = await eCommService.addAddress({
          cartId: id,
          addresses: [
            {
              address: {
                deliveryAddress: {
                  address1: shippingDetails.address,
                  address2: shippingDetails.address2,
                  city: shippingDetails.city,
                  provinceCode: shippingDetails.state,
                  countryCode: shippingDetails.country,
                  zip: shippingDetails.postalCode,
                  firstName: shippingDetails.firstName,
                  lastName: shippingDetails.lastName,
                  phone: shippingDetails.phoneNumber,
                },
              },
              selected: true,
            },
          ],
        });

        commit('setDeliveryAddressId', readyCart.deliveryAddressId, { root: true });
      }

      if (couponName) {
        readyCart = await eCommService.updateCartCoupon({ cartId: id, discountCodes: [couponName], currentSubtotalInCents: readyCart.subTotal });
      }
    }

    const {
      amountOffInCents: discountAmount = 0, discount, totalPrice, subTotal, shippingAmount, shippingRates,
    } = readyCart || {};

    const couponCode = discount?.name ?? null;
    const couponData = couponCode ? {
      discountPrice: discount?.amountOff ?? 0,
      couponType: discount?.type ?? null,
    } : null;

    const orderObj = {
      costBreakdown: {
        totalCost: totalPrice ?? 0,
        productTax: 0,
        productCost: subTotal ?? 0,
        productShipping: shippingAmount,
        couponData,
        ...(discountAmount ? { discountAmount } : {}),
        ...(couponCode ? { couponName: couponCode } : {}),
      },
      shippingRateDetails: shippingRates ? shippingRates[0] : null,
      totalDuties: null,
    };

    commit('updateOrder', orderObj, { root: true });
    // to get taxes and duties from Shopify DraftOrder since Cart API does not return them
    await dispatch('orders/createOrUpdateOrder', { shippingDetails, ordersApi, bugsnag }, { root: true });

    return orderObj;
  } catch (err: any) {
    // we handle and commit all known errors here so that in the action we only commit unknown errors and those are reported to bugsnag.

    const errorHandlers: Record<string, () => object | null> = {
      UnshippableError: () => {
        unshippableError({
          commit, productIds: err.productIds, shopifyItemsInCart, dispatch,
        });
        return null;
      },
      ProductAvailabilityError: () => {
        productAvailabilityError({ commit, products: err.products, shopifyItemsInCart });
        return null;
      },
      ShippingError: () => {
        const errorMessage = err.message === 'MISSING_SHIPPING_RATE'
          ? 'Product currently not available.'
          : err.message;
        commit('SET_SHIPPING_ERROR', errorMessage, { root: true });
        return null;
      },
      CouponError: () => {
        commit('updateCouponError', err.message, { root: true });
        return null;
      },
    };

    const handleError = errorHandlers[err.name as keyof typeof errorHandlers];
    if (handleError) {
      const result = handleError();
      commit('setProcessingTotalCost', false, { root: true });
      return result;
    }

    // If no handler is found, rethrow the error to be handled elsewhere.
    throw new Error(err.message);
  }
}
