import {
  IShippingDetails, ICartItem, ICostBreakdown, IShippingRateDetails,
} from '~/store/types';
import { IOrderRequestBody } from '~/services/OrdersService';

interface FormatOrderRequestBodyParams {
  cart: ICartItem[];
  costBreakdown: ICostBreakdown;
  shippingRateDetails?: IShippingRateDetails | null;
  customerId?: string | null;
  setupIntentId?: string | null;
  draftOrderId: string;
  paymentMethod?: 'affirm' | 'stripe';
  shippingDetails: IShippingDetails;
  tags?: string[] | null;
  paymentIntentId?: string | null;
}

export function formatOrderRequestBody({
  paymentIntentId = null, shippingDetails, cart, costBreakdown, shippingRateDetails = null, customerId = null, setupIntentId = null, draftOrderId, tags = null, paymentMethod = null,
}: FormatOrderRequestBodyParams): IOrderRequestBody {
  const {
    email, address, address2, city, state, country, firstName, lastName, phoneNumber, postalCode,
  } = shippingDetails;
  return {
    order: {
      currency: 'usd',
      email,
      itemList: cart,
      shippingDetails: {
        firstName,
        lastName,
        phone: phoneNumber,
        address,
        address2,
        city,
        state,
        country,
        postalCode,
      },
      ...(tags ? { tags } : {}),
    },
    shippingRateDetails,
    costBreakdown,
    customerId,
    setupIntentId,
    draftOrderId,
    paymentMethod,
    paymentIntentId,
  };
}

export function createOrderTags({ isEligibleGen2Upgrade, includesConsole }: { isEligibleGen2Upgrade: boolean, includesConsole: boolean }) {
  const tags = [];

  if (isEligibleGen2Upgrade) {
    tags.push('Gen2 Existing Customer Promo');
  }

  if (includesConsole) {
    tags.push('includesConsole');
  }

  return tags;
}
