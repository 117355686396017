import { ActionContext } from 'vuex';
import { useAnalytics } from '@fc/app-common';
import sessionData from '@/shared/utils/sessionData';
import EventBus from '@/shared/scripts/eventbus';
import {
  CheckoutState, ICartItem, IShopifyItem,
} from '~/store/types';
import { formatOrderRequestBody, createOrderTags } from '~/utils/ordersHelpers';
import { IOrdersService } from '~/services/OrdersService';
import { IBugsnagWrapper } from '~/services/BugSnagService';
import { transformShopifyProductForAnalytics } from '~/selectors/selectors';
import { handleShopifyAdminErrors } from '~/utils/errors';

interface confirmOrderParams {
  ordersApi: IOrdersService;
  bugsnag: IBugsnagWrapper;
}

const analytics = useAnalytics();

export async function confirmOrder({
  commit, rootState, rootGetters,
}: ActionContext<CheckoutState, any>, payload: confirmOrderParams): Promise<void> {
  commit('SET_PROCESSING_PAYMENT_METHOD', true, { root: true });
  commit('SET_PAYMENT_ATTEMPT_ERROR', '', { root: true });

  console.info('action - confirmOrder');

  const { bugsnag, ordersApi } = payload;

  const {
    paymentIntentId,
    draftOrderId,
    setupIntentId,
    shipping,
    cart,
    customerId,
    affirmToken,
    order: {
      costBreakdown,
      shippingRateDetails,
    },
    new_customer: newCustomer,
    checkoutEmail: email,
    paymentInformation: {
      paymentMethodObj,
      paymentMethod,
    },
  } = rootState.checkout;

  try {
    if (!draftOrderId) {
      throw new Error('Draft Order ID is missing');
    }

    const cartItems = (cart as ICartItem[]).map((item) => ({
      id: item.id,
      qty: item.qty,
      type: item.type,
      uid: getUIDForItem({ shopifyItems: rootGetters?.cartProducts?.shopifyItems, id: item.id }),
    }));

    if (paymentIntentId) {
      // need to update the Payment Intent with the customerId so that it attaches the customer to the card for subscriptions
      // and lets also update shipping details, cart, costBreakdown in case they have changed
      const tags = createOrderTags({ isEligibleGen2Upgrade: rootGetters.isEligibleGen2Upgrade, includesConsole: rootGetters.includesConsole });

      const updateRequestBody = formatOrderRequestBody({
        paymentIntentId, shippingDetails: shipping, cart: cartItems, costBreakdown, customerId, draftOrderId, tags, paymentMethod,
      });

      await ordersApi.updateOrder({ draftOrderId: rootGetters.plainShopifyDraftOrderID, requestBody: updateRequestBody });
    }

    const membership = getMembership({ state: rootState, getters: rootGetters, bugsnag });

    const requestBody = {
      email,
      shippingInfo: {
        address: shipping.address,
        address2: shipping.address2,
        city: shipping.city,
        state: shipping.state,
        country: shipping.country,
        postalCode: shipping.postalCode,
        firstName: shipping.firstName,
        lastName: shipping.lastName,
        phoneNumber: shipping.phoneNumber,
        email,
      },
      costBreakdown,
      shippingRateDetails,
      productList: cartItems,
      membership,
      affirmLoanId: affirmToken,
      customerId,
      paymentMethodId: paymentMethodObj?.id,
      options: {
        paymentMethod,
        retail: null,
        insideSale: null,
      },
      sessionData: sessionData.get(),
      newCustomer,
      paymentIntentId,
      draftOrderId,
      setupIntentId,
      anonymousId: typeof window?.analytics?.user === 'function' ? window.analytics.user()?.anonymousId() : null,
    };

    const {
      data: {
        orderName, orderID, method, experiments,
      },
    } = await ordersApi.confirmOrder({ requestBody });

    commit('setConfirmation', {
      email,
      textPhoneNumber: shipping.phoneNumber,
      orderNumber: parseInt(orderName.split('#')[1], 10), // orderNumber needs to be an integer and cannot have # in it
      orderID,
      order: rootState.checkout.order,
      method,
      cart,
      isNewCustomer: newCustomer,
      serverExperiments: experiments, // TODO
      name: `${shipping.firstName} ${shipping.lastName}`,
      subscription: rootGetters.membershipInCart,
    }, { root: true });

    const products = (rootGetters?.cartProducts?.shopifyItems as IShopifyItem[])?.map(item => transformShopifyProductForAnalytics({ product: item, variantId: item?.variant?.id }));

    analytics.orderCompleted({ ...rootGetters.confirmation, products, packageInCart: !!rootGetters.packageInCart });

    commit('reset', null, { root: true });
    commit('resetCheckout', null, { root: true });
  } catch (err: any) {
    bugsnag.notify(err);

    if (err?.response?.data.type === 'StripeError') {
      throw new Error(err.response.data.message);
    }

    let errorMessage: string | string[] = 'An unexpected error occurred. Please try again later.';
    if (err.response) {
      const { name, message, errors } = err.response.data;

      if (name === 'ShopifyError') {
        const messagesToDisplay = handleShopifyAdminErrors(errors, commit);
        errorMessage = messagesToDisplay;
        EventBus.$emit('setTab', 'information');
        bugsnag.notify(new Error(messagesToDisplay.join(' ')), 'error', 'confirmOrder/ShopifyError');

        commit('SET_SHIPPING_ERROR', errorMessage, { root: true });
        commit('setProcessingTotalCost', false, { root: true });
        return;
      }

      bugsnag.notify(new Error(message), 'error', 'confirmOrder/Response Error');
    }

    if (err.message === 'Draft Order ID is missing') {
      throw new Error('Sorry, there was an error placing the order. Please refresh and try again.');
    }

    throw new Error(err);
  }
}

function getMembership({ state, getters, bugsnag }) {
  if (getters?.membershipInCart) {
    return {
      id: getters?.membershipInCart?.metadata?.stripeSubId,
      trialDays: parseInt(getters.membershipInCart.metadata.stripeTrialDays, 10),
      stripeCoupon: getters.membershipInCart.metadata.stripeCoupon,
    };
  } if (getters?.isHolidayPackageInCart) {
    const getAnnualSub = state?.app?.productsCollection.find(product => product?.variants?.[0]?.uid === 'fightcamp_subscription_yearly_included');

    // TODO throw bugsnag error
    if (!getAnnualSub) bugsnag.notify(new Error('Could not find Annual Subscripition in productsCollection'));
    return {
      id: getAnnualSub?.metadata?.stripeSubId,
      trialDays: parseInt(getAnnualSub.metadata.stripeTrialDays, 10),
      stripeCoupon: getAnnualSub.metadata.stripeCoupon,
    };
  }

  return null;
}

function getUIDForItem({ shopifyItems, id }: { shopifyItems: IShopifyItem[], id: string }) {
  return shopifyItems?.find((product) => product.variant.id === id)?.variant?.uid ?? null;
}
