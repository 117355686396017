import axios from 'axios';

interface ICustomerIO {
  brochureDownloadWebhook(email: string): void;
}

export const CustomerIOWrapper: ICustomerIO = {
  brochureDownloadWebhook: async (email: string) => {
    await axios.post('/.netlify/functions/cioBrochureDownloadWebhook', { email });
  },
};
