import SharedEventBus from '@/shared/scripts/eventbus';
import analytics from '~/services/analytics/analytics';

SharedEventBus.$on('trackPurchase', (purchase) => {
  if (purchase.items) {
    // send purchase event to GA
    analytics.purchase({
      items: purchase.items,
      order: purchase.order,
      orderNumber: purchase.orderNumber,
      bundle: purchase.bundle,
      newCustomer: purchase.isNewCustomer,
      method: purchase.method,
    });
  } else {
    // keeping this to not break biz dev for now
    analytics.trackPurchase(
      purchase.order,
      purchase.bundle,
      purchase.method,
      purchase.orderNumber,
      purchase.isNewCustomer,
    );
  }
});

SharedEventBus.$on('trackTransactionAttempt', (attempt) => {
  analytics.trackTransactionAttempt(
    attempt.bundle,
    attempt.method,
  );
});

SharedEventBus.$on('trackHeardAboutUs', (survey) => {
  analytics.trackHeardAboutUs(
    survey.email,
    survey.orderID,
    survey.fullAnswer,
    survey.salesRep,
    survey.followup,
  );
});

SharedEventBus.$on('trackCartSync', (state) => {
  analytics.trackCartSync(state);
});

SharedEventBus.$on('trackRemoveFromCart', (state) => {
  analytics.trackRemoveFromCart(state);
});
