import { IGA4ProductItem, IGA4Purchase } from './types';

function addUserProperties(props) {
  console.log(`Added User Propeties > ${JSON.stringify(props, null, 2)}`);
  window.dataLayer.push({
    event: 'AddUserProperties',
    userProperties: props,
  });
}

function computeValue(productList: IGA4ProductItem[]): number {
  return parseFloat(productList.reduce((acc, product) => acc + (parseFloat(product.price) / 100 * (product?.quantity ?? 1)), 0).toFixed(2));
}

function convertToDollars(cents: number | string): number {
  const centsValue = typeof cents === 'string' ? parseFloat(cents) : cents;
  return parseFloat((centsValue / 100.0).toFixed(2));
}

export function getPurchaseType(productList: IGA4ProductItem[]) {
  const categories = new Set(productList.map(product => product.item_category));
  if (categories.has('package')) return 'package';
  if (categories.has('subscription')) return 'subscription';
  if (categories.size === 1 && categories.has('accessory')) return 'accessory';
  return 'other';
}

export default class Analytics {
  // GA4 Events
  static view_cart(productList: IGA4ProductItem[]) {
    console.log(`view_cart > ${JSON.stringify(productList.map(p => p?.item_id))}`);
    window.dataLayer.push({
      event: 'view_cart',
      currency: 'USD',
      value: computeValue(productList),
      items: productList.map(product => ({
        ...product,
        price: convertToDollars(product?.price),
      })),
    });
  }

  static view_item(productList: IGA4ProductItem[]) {
    console.log(`view_item > ${JSON.stringify(productList.map(p => p?.item_id))}`);

    window.dataLayer.push({
      event: 'view_item',
      product: {
        value: convertToDollars(productList[0].price),
        items: productList.map(product => ({
          ...product,
          price: convertToDollars(product?.price),
        })),
      },
    });
  }

  static add_to_cart(productList: IGA4ProductItem[]) {
    console.log(`add_to_cart > ${JSON.stringify(productList.map(p => p?.item_id))}`);

    window.dataLayer.push({
      event: 'add_to_cart',
      product: {
        value: computeValue(productList),
        items: productList.map(product => ({
          ...product,
          price: convertToDollars(product?.price),
        })),
      },
      aw_value: computeValue(productList),
      aw_items: productList.map(product => ({
        id: product?.item_id,
        name: product?.item_name,
        price: convertToDollars(product?.price),
        quantity: product?.quantity,
        google_business_vertical: 'retail',
      })),
    });
  }

  static remove_from_cart(productList: IGA4ProductItem[]) {
    console.log(`remove_from_cart > ${JSON.stringify(productList.map(p => p?.item_id))}`);

    window.dataLayer.push({
      event: 'remove_from_cart',
      product: {
        value: computeValue(productList),
        items: productList.map(product => ({
          ...product,
          price: convertToDollars(product?.price),
        })),
      },
      aw_value: computeValue(productList),
      aw_items: productList.map(product => ({
        id: product?.item_id,
        name: product?.item_name,
        price: convertToDollars(product?.price),
        quantity: product?.quantity,
        google_business_vertical: 'retail',
      })),
    });
  }

  static purchase({
    items, orderNumber, order, bundle, newCustomer, method,
  }: IGA4Purchase) {
    console.log(`purchase > ${orderNumber} ${JSON.stringify(items.map(item => item?.item_id))}`);

    const purchaseType = getPurchaseType(items);
    const value = order.costBreakdown.totalCost === 0 ? 0 : convertToDollars(order.costBreakdown.productCost ?? 0);
    window.dataLayer.push({
      event: 'purchase',
      currency: 'USD',
      value,
      transaction_id: orderNumber,
      productShipping: convertToDollars(order.costBreakdown.productShipping ?? 0),
      productTax: convertToDollars(order.costBreakdown.productTax ?? 0),
      items: items.map(product => ({
        ...product,
        price: convertToDollars(product?.price),
      })),
      purchase_type: purchaseType, //  can be 'package', 'accessory', 'subscription', or 'other'
      aw_value: value,
      aw_items: items.map(product => ({
        id: product?.item_id,
        name: product?.item_name,
        price: convertToDollars(product?.price),
        quantity: product?.quantity,
      })),
      // custom property to keep a lot of old GTM variables working
      order: {
        cart: bundle.map(variant => ({
          ...variant,
          price: convertToDollars(variant?.price),
        })),
        id: orderNumber,
        affiliation: 'Online Store',
        revenue: convertToDollars(order.costBreakdown.totalCost ?? 0), // Total transaction value (incl. tax and shipping)
        tax: convertToDollars(order.costBreakdown.productTax ?? 0),
        shipping: convertToDollars(order.costBreakdown.productShipping ?? 0),
        coupon: order.costBreakdown.couponName ?? '',
        method,
        new_customer: newCustomer,
      },
    });
  }

  // any moment we capture an email add a “campaign” event with campaign_action = “subscribed” so if they added it in popup vs checkout vs placing order, etc
  static campaign(campaign: { name: string; action: string; type: 'modal' | 'footer' | 'purchase', email?: string }) {
    console.log(`campaign> ${campaign.name} > ${campaign.action}`);
    window.dataLayer.push({
      event: 'campaign',
      campaign,
    });
  }

  static pushToDataLayer(data: Record<string, any>) {
    console.log(`pushToDataLayer > ${JSON.stringify(data)}`);
    window.dataLayer.push({
      ...data,
    });
  }

  static trackEvent(eventName: string, data: Record<string, any>) {
    console.log(`trackEvent > ${eventName} ${JSON.stringify(data)}`);
    window.dataLayer.push({
      event: eventName,
      ...data,
    });
  }

  // Legacy Events below
  static trackAddToCart(variantList) {
    window.dataLayer.push({
      event: 'add_to_cart',
      items: variantList.map(product => ({
        item_id: product?.id || product?.product?.id,
        item_name: product?.productName || product?.product?.title,
        price: parseFloat((product?.price / 100.0).toFixed(2)),
        quantity: 1,
      })),
      aw_value: parseFloat((variantList.reduce((acc, product) => acc + product?.price, 0) / 100).toFixed(2)),
      aw_items: variantList.map(product => ({
        id: (product?.id || product?.product?.id) ?? '',
        google_business_vertical: 'retail',
      })),
      legacy_checkout: true,
    });

    return parseFloat((variantList.reduce((acc, product) => acc + product?.price, 0) / 100).toFixed(2));
  }

  static trackRemoveFromCart(variantList) {
    console.log(`Remove From Cart > ${JSON.stringify(variantList.map(p => p?.id || p?.product?.id))}`);

    window.dataLayer.push({
      event: 'remove_from_cart',
      items: variantList.map(product => ({
        item_id: product?.id || product?.product?.id,
        item_name: product?.productName || product?.product?.title,
        price: parseFloat((product?.price / 100.0).toFixed(2)),
        quantity: 1,
      })),
      legacy_checkout: true,
    });
  }

  static trackCheckoutPayment(variantList) {
    console.log(`Checkout Payment > ${JSON.stringify(variantList.map(p => p?.id || p?.product?.id))}`);
    window.dataLayer.push({
      event: 'eec.CheckoutPayment',
      ecommerce: {
        currencyCode: 'USD',
        checkout: {
          actionField: {
            step: 3,
          },
          products: variantList?.map((variant) => ({
            name: variant?.title || variant?.product?.title,
            id: variant?.product?.id,
            price: parseFloat((variant?.price / 100.0)).toFixed(2),
            brand: 'FightCamp',
            category: variant?.category || variant?.product?.category,
            quantity: variant?.qty,
            variant: variant?.id,
          })),
        },
      },
    });
  }

  static trackTransactionAttempt(variantList, method) {
    console.log(`Transaction Attempt > ${method} - ${JSON.stringify(variantList.map(p => p?.id || p?.product?.id))}`);
    window.dataLayer.push({
      event: 'eec.TransactionAttempt',
      ecommerce: {
        currencyCode: 'USD',
        checkout: {
          actionField: {
            step: 4,
            option: method,
          },
          products: variantList.map((variant) => ({
            name: variant?.title || variant?.product?.title,
            id: variant?.id || variant?.product?.id,
            price: parseFloat((variant?.price / 100.0)).toFixed(2),
            brand: 'FightCamp',
            category: variant?.category || variant?.product?.category,
            quantity: variant?.qty,
            variant: variant?.id,
          })),
        },
      },
    });

    window.dataLayer.push({
      event: 'TransactionAttempt',
      transactionAttempt: {
        method,

      },
    });
  }

  static trackPurchase(order, bundle, method, orderNumber, newCustomer) {
    console.log(`Checkout Purchase > ${orderNumber} ${JSON.stringify(bundle.map(p => p?.title || p?.product?.title))}`);
    window.dataLayer.push({
      event: 'eec.Purchase',
      ecommerce: {
        currencyCode: 'USD',
        purchase: {
          actionField: {
            id: orderNumber, // Transaction ID. Required for purchases and refunds.
            affiliation: 'Online Store',
            revenue: parseFloat(order.costBreakdown.totalCost / 100.0).toFixed(2), // Total transaction value (incl. tax and shipping)
            tax: parseFloat(order.costBreakdown.productTax / 100.0).toFixed(2),
            shipping: parseFloat(order.costBreakdown.productShipping / 100.0).toFixed(2),
            coupon: order.costBreakdown.couponName,
          },
          products: bundle.map(variant => ({
            name: variant?.title || variant?.product?.title,
            id: variant?.id || variant?.product?.id,
            price: parseFloat((variant?.price || variant?.product?.price / 100.0)).toFixed(2),
            brand: 'FightCamp',
            category: variant?.category || variant?.product?.category,
            quantity: variant?.qty,
            variant: variant?.id,
            subid: variant.subid,
          })),
        },
      },
    });

    window.dataLayer.push({
      event: 'Purchase',
      order: {
        cart: bundle.map(variant => ({
          ...variant,
          price: parseFloat(variant?.price / 100.0).toFixed(2),
        })),
        id: orderNumber,
        affiliation: 'Online Store',
        revenue: parseFloat(order.costBreakdown.totalCost / 100.0).toFixed(2), // Total transaction value (incl. tax and shipping)
        tax: parseFloat(order.costBreakdown.productTax / 100.0).toFixed(2),
        shipping: parseFloat(order.costBreakdown.productShipping / 100.0).toFixed(2),
        coupon: order.costBreakdown.couponName,
        method,
        new_customer: newCustomer,
      },
    });
  }

  static trackCartSync(state) {
    console.log('Cart Sync');
    window.dataLayer.push({
      event: 'CartReset',
      email: undefined,
      cart: undefined,
      coupon: undefined,
      shipping: undefined,
    });
    window.dataLayer.push({
      event: 'CartSync',
      email: state.email,
      cart: state.cart,
      coupon: state.coupon,
      shipping: state.shipping,
    });
  }

  static trackHeardAboutUs(email, orderId, heardText, salesRep = null, followup = null) {
    console.log(`Checkout Purchase > ${email}, ${orderId},${heardText},${salesRep},${followup},`);
    window.dataLayer.push({
      event: 'HeardAboutUsSurvey',
      heardAboutUs: {
        email,
        orderId,
        heardText,
        salesRep,
        followup,
      },
    });
  }

  static identifyByID(id) {
    console.log(`Identify By ID > ${id}`);
    window.dataLayer.push({
      event: 'IdentifyByID',
      userID: id,
    });
  }

  static identifyByEmail(email) {
    console.log(`Identify By Email > ${email}`);
    window.dataLayer.push({
      event: 'IdentifyByEmail',
      userEmail: email,
    });
  }

  static brochurePopUp(state) {
    console.log(`Brochure Pop Up > ${state}`);
    window.dataLayer.push({
      event: `BrochurePopUp${state}`,
    });
  }

  static brochureFooter(state) {
    console.log(`Brochure Footer > ${state}`);
    window.dataLayer.push({
      event: `BrochureFooter${state}`,
    });
  }

  static trackPromoCodeAttempted(name, valid, error) {
    console.log(`Attempted Promo Code > ${name}`);
    window.dataLayer.push({
      event: 'AttemptedPromoCode',
      promoCode: {
        name,
        valid,
        error,
      },

    });
  }

  static setName(first, last) {
    console.log(`Set Name properties > ${first} ${last}`);
    addUserProperties({
      firstName: first,
      lastName: last,
    });
  }

  static setPhoneNumber(phone) {
    console.log(`Set Phone property > ${phone}`);
    addUserProperties({
      phone,
    });
  }

  static setAddress(address, state, postalCode, city, address2) {
    console.log(`Set Address property > ${JSON.stringify(address, null, 2)}`);
    console.log(`Set state property > ${JSON.stringify(state, null, 2)}`);
    console.log(`Set postalCode property > ${JSON.stringify(postalCode, null, 2)}`);
    console.log(`Set city property > ${JSON.stringify(city, null, 2)}`);
    console.log(`Set address2 property > ${JSON.stringify(address2, null, 2)}`);
    addUserProperties({
      address,
      state,
      postalCode,
      city,
      address2,
    });
  }

  static abandonedCart(variants) {
    const products = variants.map(variant => variant.product).filter(el => el != null);
    console.log(`Abandoned Cart > ${products.map(product => product.id)}`);
    window.dataLayer.push({
      event: 'AbandonedCart',
    });
  }

  static triggerDrift() {
    window.dataLayer.push({
      event: 'triggerDrift',
    });
  }
}
